import React from 'react'; //, { useContext }
import { Outlet } from 'react-router-dom'; // , Link
// import { GlobalPageNameStateContext } from '../context/GlobalState';
// import MainNav from './common/MainNav';

function App() {
  // const currPage = useContext(GlobalPageNameStateContext);

  return (
    <div >

      <Outlet 
        message="test message"
        />

    </div>
  );
}

export default App;


 
